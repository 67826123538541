.carousel-image-mobile
{
    display: inline-block;

    width: 50%;
    height: 200px;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

/* MD */
@media (min-width: 768px)
{
    .carousel-image-mobile
    {
        height: 300px;
    }
}

.carousel-image-desktop
{
    display: inline-block;

    width: 33.33%;
    height: 300px;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}