.panel-section-continuous
{
    padding-top: 0px;
    padding-bottom: 96px;

    background-color: #f6f6f6;
}

.panel-section-single
{
    padding-top: 48px;
    padding-bottom: 48px;

    background-color: #f6f6f6;
}

.panel-section-single-wide
{
    margin-top: 48px;
    margin-bottom: 48px;

    background-color: #ffffff;
}

.panel-header
{
    display: flex;
    flex-flow: column;

    margin-top: 0px;
    margin-bottom: 24px;
}

.panel-header-text-mobile
{
    font-size: 24px;
    line-height: 29px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.panel-header-text-desktop
{
    font-size: 30px;
    line-height: 36px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.panel-header-text-large-mobile
{
    margin-bottom: 16px;

    font-size: 28px;
    line-height: 34px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.panel-header-text-large-tablet
{
    margin-bottom: 16px;

    font-size: 34px;
    line-height: 40px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.panel-header-text-large-desktop
{
    margin-bottom: 16px;

    font-size: 44px;
    line-height: 48px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.panel-content-contain-mobile
{
    display: flex;
    flex-flow: column;

    margin-top: 24px;
    margin-bottom: 0px;
    padding: 24px;

    height: 550px;
    
    border-radius: 24px;

    background-color: #ffffff;
    
    box-shadow: 0 10px 32px #0000000f;
}

.panel-content-contain-tablet
{
    display: flex;
    flex-flow: column;

    margin-top: 24px;
    margin-bottom: 0px;
    padding: 24px;

    height: 550px;
    
    border-radius: 24px;

    background-color: #ffffff;

    box-shadow: 0 10px 32px #0000000f;
}

.panel-content-contain-desktop
{
    display: flex;
    flex-flow: column;

    margin-top: 24px;
    margin-bottom: 0px;
    padding: 48px;
    padding-top: 40px;

    height: 550px;
    
    border-radius: 24px;

    background-color: #ffffff;

    box-shadow: 0 10px 32px #0000000f;
}

.panel-content-contain-mobile-icon
{
    display: flex;
    flex-flow: column;

    margin-top: 24px;
    margin-bottom: 0px;
    padding: 24px;

    height: 450px;
    
    border-radius: 24px;

    background-color: #ffffff;
    
    box-shadow: 0 10px 32px #0000000f;
}

.panel-content-contain-tablet-icon
{
    display: flex;
    flex-flow: column;

    margin-top: 24px;
    margin-bottom: 0px;
    padding: 24px;

    height: 450px;
    
    border-radius: 24px;

    background-color: #ffffff;

    box-shadow: 0 10px 32px #0000000f;
}

.panel-content-contain-desktop-icon
{
    display: flex;
    flex-flow: column;

    margin-top: 24px;
    margin-bottom: 0px;
    padding: 48px;
    padding-top: 40px;

    height: 450px;
    
    border-radius: 24px;

    background-color: #ffffff;

    box-shadow: 0 10px 32px #0000000f;
}

.panel-content-image-contain
{
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;

    width: 100%;
    height: 100%;
    
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.panel-content-image-contain-icon
{
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;

    width: 100%;
    max-width: 160px;
    height: 100%;
    
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.panel-content-text-background
{
    padding-top: 8px;
    padding-bottom: 0px;
    margin-bottom: 0px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    color: rgb(65, 65, 65);
}

.panel-content-text-black
{
    padding-top: 8px;
    padding-bottom: 0px;
    margin-bottom: 0px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.panel-content-text
{
    padding-top: 16px;
    padding-bottom: 0px;
    margin-bottom: 0px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.panel-content-column
{
    position: relative;

    padding-top: 40px;
    padding-left: 48px;
    padding-right: 48px;
    padding-bottom: 48px;

    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;

    background-color: #ffffff;

    min-height: 512px;

    border-radius: 24px;

    box-shadow: 0 10px 32px #0000000f;
}

/* XS, SM */
@media (max-width: 767.98px)
{
    .panel-content-column
    {
        padding: 24px;
    }
}

.panel-content-column-image
{
    margin-top: 40px;
    margin-bottom: 24px;
    
    border-radius: 6px;
}

.panel-content-column-sidemap
{
    position: relative;

    margin-top: 48px;
    margin-left: -24px;

    height: 500px;

    background-size: cover;
    background-position: center;

    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;

    box-shadow: 0 10px 32px #0000000f;
    
    background-color: #ffffff;
}

.panel-content-label-title
{
    color: #000000;

    font-size: 20px;
    font-weight: 600;
}

.panel-content-label-logo-mobile
{
    margin-top: 24px;

    width: 120px;
    height: 120px;
    
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
}

.panel-content-label-logo-desktop
{
    margin-top: 24px;

    width: 160px;
    height: 160px;
    
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
}

.panel-content-contact-navigate
{
    margin-top: 24px;
    margin-bottom: 24px;

    border-radius: 6px;
}

.panel-icon-thumbsup
{
    display: inline-block;
    
    margin-left: 6px;

    width: 24px;
    height: 16px;
    
    background-image: url("/public/image/icon_thumbsup.svg");
    background-repeat: no-repeat;
    background-size: contain;
}