.banner-section
{
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    height: calc(100vh - 40px);
    max-height: 560px;
    min-height: 460px;
    overflow: hidden;
    
    background-color: #333;
    background-position: center top;
    background-size: cover;
}

.banner-video
{
    position: absolute;
    z-index: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* XS, SM */
@media (max-width: 767.98px)
{
    .banner-video-autoglaslimburg
    {
        width: initial;
        transform: translate(-25%, -50%);
    }
}

/* MD */
@media (min-width: 768px) and (max-width: 991.98px)
{
    .banner-video-autoglaslimburg
    {
        width: initial;
        transform: translate(-35%, -50%);
    }
}

.banner-darken
{
    position: absolute;
    display: flex;
    align-items: center;

    width: 100%;
    height: 100%;
    
    background-color: #0000007d;
}

.banner-overlay
{
    position: absolute;
    display: flex;
    width: 100%;
}

.banner-logo-tablet
{
    display: inline-block;
    position: absolute;

    width: 124px;
    height: 124px;
    
    background-image: url("/public/image/element_123ruit.png");
    background-repeat: no-repeat;
    background-size: contain;
}

.banner-logo-desktop
{
    display: inline-block;
    position: absolute;

    width: 164px;
    height: 164px;
    
    background-image: url("/public/image/element_123ruit.png");
    background-repeat: no-repeat;
    background-size: contain;
}

.banner-logo-text-tablet
{
    margin-left: 144px;
}

.banner-logo-text-desktop
{
    margin-left: 184px;
}

.banner-title-mobile
{
    margin: 0;
    margin-top: 12px;
    margin-bottom: 0px;

    font-size: 28px;
    line-height: 34px;

    font-weight: 600;
    -webkit-font-smoothing: antialiased;

    color: #ffffff;
}

.banner-title-tablet
{
    margin: 0;
    margin-top: 12px;
    margin-bottom: 0px;

    font-size: 34px;
    line-height: 40px;

    font-weight: 600;
    -webkit-font-smoothing: antialiased;

    color: #ffffff;
}

.banner-title-desktop
{
    margin: 0;
    margin-top: 12px;
    margin-bottom: 0px;

    font-size: 44px;
    line-height: 48px;
    
    font-weight: 600;
    -webkit-font-smoothing: antialiased;

    color: #ffffff;
}

.banner-text
{
    margin-top: 12px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    color: #ffffff;
}

.banner-button-primary
{
    display: inline-block;

    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 32px;
    padding-right: 32px;
    margin-top: 12px;
    margin-right: 16px;

    background-color: #01923f;
    color: #ffffff;

    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.125);

    border-color: #01923f;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;

    transition: all .25s ease-out;
}

.banner-button-primary:hover
{
    background-color: #0ba04c;
    color: #ffffff;

    text-decoration: none;

    border-color: #01923f;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;

    cursor: pointer;

    transition: all .25s ease-out;
}

.banner-button-secondary
{
    display: inline-block;

    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 32px;
    padding-right: 32px;
    margin-top: 12px;
    margin-right: 16px;

    color: #ffffff;

    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.125);

    border-color: #ffffff;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;

    transition: all .25s ease-out;
}

.banner-button-secondary:hover
{
    background-color: #ffffff;
    color: #000000;

    text-decoration: none;

    border-color: #ffffff;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;

    cursor: pointer;

    transition: all .25s ease-out;
}

.banner-button-splittext
{
    margin-right: 16px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    color: #ffffff;
}

.banner-reviews-mobile
{
    margin-top: 36px;
    padding-top: 6px;
    padding-bottom: 6px;
    
    width: 270px;
    height: 72px;

    background-color: #ffffff;

    border-radius: 6px;
}

.banner-link-external-icon
{
    display: inline-block;

    margin-left: 7px;

    width: 12px;
    height: 12px;
    
    background-image: url("/public/image/icon_external_link.svg");
    background-repeat: no-repeat;
    background-size: contain;
}