.footer-section
{
    padding-top: 48px;
    padding-bottom: 48px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;

    color: #ffffff;

    background-color: #252525;
    background-position: center top;
    background-size: cover;
}

.footer-line
{
    margin-top: 12px;
    margin-bottom: 12px;

    background-color: #939393;
    color: #939393;
}

/* XS */
@media (max-width: 575.98px)
{
    .footer-line
    {
        margin-top: 24px;
        margin-bottom: 24px;
    }
}

.footer-spacing
{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-text
{
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;

    color: #939393;
}

.footer-link
{
    display: inline-block;

    margin-right: 24px;
    
    color: #FFFFFF;
}

.footer-link:hover
{
    color: #FFFFFF;

    cursor: pointer;

    text-decoration: underline;
}

.footer-link-alt
{
    display: inline-block;
    
    color: #298ce8;
}

.footer-link-alt:hover
{
    cursor: pointer;

    color: #0056b3;
    text-decoration: underline;
}