.status-section
{
    background-color: #fffdd9;
}

.status-title
{
    display: inline-block;

    padding-top: 4px;
    padding-bottom: 6px;

    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}