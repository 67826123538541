.navbar-section
{
    background: #ffffff;
}

.navbar-container
{
    padding-top: 0px;
    padding-bottom: 0px;

    min-height: 60px;
}

.navbar-justify-left
{
    justify-content: left;
}

.navbar-justify-right
{
    justify-content: right;
}

.navbar-title-container
{
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.navbar-title
{
    display: inline-block;

    width: 210px;
    height: 60px;
    
    background-image: url("/public/image/logo_autoglaslimburg.webp");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left center;

    border-top: 6px solid #ffffff;
}

.navbar-title:hover
{
    text-decoration: none;

    cursor: pointer;
}

.navbar-divider
{
    display: inline-block;

    margin-left: 16px;
    margin-right: 0px;

    border-left: 1px solid #212529;
    height: 24px;
}

.navbar-item-subtitle
{
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    
    height: 60px;
    margin-left: 16px;
    margin-right: 0px;
    
    color: #212529;

    font-size: 16px;
    font-weight: 400;
    text-decoration: none;

    border-top: 3px solid #FFFFFF;
    border-bottom: 3px solid #FFFFFF;

    transition: all .25s ease-out;
}

.navbar-item-subtitle:hover
{
    color: #01923f;

    border-top: 3px solid #FFFFFF;
    border-bottom: 3px solid #01923f;

    text-decoration: none;
    cursor: pointer;
    
    transition: all .25s ease-out;
}

/* Used by Bootstrap */
.navbar-toggler
{
    border-style: solid;
    border-width: 1px;
    border-color: #000000;
    border-radius: 8px;

    /* Fix for button displacement on dropdown open */
    height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.navbar-item
{
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    
    height: 100%;
    margin-left: 32px;
    margin-right: 0px;

    color: #212529;
    border-top: 3px solid #FFFFFF;
    border-bottom: 3px solid #FFFFFF;

    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    line-height: 18px;
    
    transition: all .25s ease-out;
}

/* <=MD */
@media (max-width: 991.98px)
{
    .navbar-item
    {
        margin-left: 0px;

        padding-top: 6px;
        padding-bottom: 6px;
    }
}

.navbar-item:hover
{
    color: #01923f;

    border-top: 3px solid #FFFFFF;
    border-bottom: 3px solid #01923f;

    text-decoration: none;
    cursor: pointer;
    
    transition: all .25s ease-out;
}

.navbar-item-reviews
{
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    
    margin-left: 32px;
    margin-right: 0px;
}

/* <=MD */
@media (max-width: 991.98px)
{
    .navbar-item-reviews
    {
        margin-left: 0px;

        padding-top: 6px;
        padding-bottom: 6px;
    }
}

.navbar-item-logo-mobile
{
    display: inline-block;
    
    font-family: Heebo;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;

    border-top: 3px solid #FFFFFF;
}

.navbar-item-logo
{
    display: inline-block;

    padding-left: 5px;
    
    font-family: Heebo;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;

    border-top: 3px solid #FFFFFF;
}

.navbar-spacing
{
    margin-top: 60px;
}

/* Full height */
.navbar-nav
{
    min-height: 60px;
}